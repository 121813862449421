import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Container from './Container'

const StyledHeader = styled.header`
  background: transparent;
  display: flex;
  align-content: center;
  justify-content: center;
  border-bottom: 1px solid gray;
`

const HeaderInner = styled(Container)`
  max-width: 860px;
  padding: 1rem 1.0875rem;
  font-size: 1.2rem;
`

const NavLink = styled(Link)`
  color: black;
  margin-left: 15px;
  text-decoration: none;
  display: inline-block;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

const HomeLink = styled(NavLink)`
  margin-left: 0;
`

interface HeaderProps {
  title: string
}

const Header: React.FC<HeaderProps> = ({ title }) => (
  <StyledHeader>
    <HeaderInner>
      <HomeLink to="/">{title}</HomeLink>
      <NavLink to="/resume">Resume</NavLink>
      <NavLink to="/charity">Charity</NavLink>
      <NavLink to="/about">About</NavLink>
      <NavLink to="/contact">Contact</NavLink>
    </HeaderInner>
  </StyledHeader>
)

export default Header
