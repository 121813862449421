import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const FooterDiv = styled.footer`
  display: flex;
  justify-content: center;
`

const FooterLink = styled(Link)`
  margin-left: 5px;
`

const Footer: React.FC = () => (
  <FooterDiv>
    © {new Date().getFullYear()}, Built with
    <FooterLink to="/about">a few different things...</FooterLink>
  </FooterDiv>
)

export default Footer
