/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'

import Header from '../components/header'
import './layout.css'
import Footer from '../components/Footer'

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 0 1.0875rem 1rem;
  padding-top: 0;
  display: flex;
  min-height: 92vh; /* Helps footer stick to bottom */
  flex-direction: column;
`
const Main = styled.main`
  flex-grow: 1;
`

const layoutQuery = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Layout: React.FC = ({ children }) => (
  <StaticQuery
    query={layoutQuery}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords }
          ]}
        />
        <Header title={data.site.siteMetadata.title} />
        <Content>
          <Main>{children}</Main>
          <Footer />
        </Content>
      </>
    )}
  />
)

export default Layout
